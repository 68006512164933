















































import Vue from 'vue'
import axios from 'axios'
// import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src
import Dmatrix from 'tualo-datamatrix'
import { dbPlanning, stringFromDate, dateFromString } from '../plugins/fonctions'

interface Planning {
  plj_id: number // int(11) NOT NULL AUTO_INCREMENT,
  plj_planning: string // varchar(200) CHARACTER SET utf8 COLLATE utf8_bin NOT NULL,
  plj_date: string // datetime NOT NULL,
  plj_codeplanning: string // varchar(20) CHARACTER SET utf8 COLLATE utf8_bin NOT NULL,
  plj_transporteur: string // text NOT NULL,
  plj_chargement: string // text CHARACTER SET utf8 COLLATE utf8_bin,
  plj_fournisseur: string // text,
}
export default Vue.extend({
  data() {
    return {
      cejour: this.jour,
      aujourhui: stringFromDate(new Date()),
      planning: [] as Planning[],
      urlapi: process.env.NODE_ENV === 'development' ? 'http://localhost/agco/agco-planningtransporteurs/php/' : 'https://planningtransporteurs.fr/php/',
      dialog: false,
      codeplanning: '',
      code2d: '',
      pixels: [] as { x: number; y: number }[],
      isCordovaApp: typeof (window as any).cordova !== 'undefined'
    }
  },
  props: ['jour', 'transporteur', 'pass', 'refresh'],
  methods: {
    actualiser() {
      this.lectureIDB()
      axios
        .post(this.urlapi + 'planning.php', {
          action: 'PLANNINGJOUR',
          jour: this.cejour,
          transporteur: this.transporteur,
          pass: this.pass
        })
        .then((response) => {
          if (response.data.planning){
            this.planning = response.data.planning.slice()
          }
          this.sauvegarderIDB()
        })
    },
    async sauvegarderIDB() {
      const s1 = this.cejour.substr(0, 10)
      const dte = dateFromString(this.cejour)
      dte.setDate(dte.getDate() + 1)
      const s2 = stringFromDate(dte).substr(0, 10)

      const db = await dbPlanning()
      await db.delete('planning_jour', IDBKeyRange.bound(s1, s2))
      const tx = db.transaction('planning_jour', 'readwrite')
      for (let i = 0; i < this.planning.length; i++) {
        tx.store.put(this.planning[i])
      }
      tx.done
      db.close()
    },
    async lectureIDB() {
      const s1 = this.cejour.substr(0, 10)
      const dte = dateFromString(this.cejour)
      dte.setDate(dte.getDate() + 1)
      const s2 = stringFromDate(dte).substr(0, 10)
      const db = await dbPlanning()
      this.planning = await db.getAllFromIndex('planning_jour', 'date', IDBKeyRange.bound(s1, s2))
      db.close()
    },
    afficherCode(codeplanning: string) {
      this.codeplanning = codeplanning
      var dm = new Dmatrix.Datamatrix()
      this.code2d = dm.getDigit(codeplanning, false)
      this.pixels = []
      let lignes = this.code2d.split('\n')
      for (let l = 0; l < lignes.length; l++) {
        const li = lignes[l]
        for (let c = 0; c < li.length; c++) {
          if (li.charAt(c) == '1') {
            this.pixels.push({ x: c, y: l })
          }
        }
      }
      this.dialog = true
    }
  },
  watch: {
    refresh() {
      this.cejour = this.jour
      this.actualiser()
    },
    jour() {
      this.cejour = this.jour
      this.actualiser()
    },
    transporteur() {
      this.actualiser()
    }
  },
  mounted() {
    this.actualiser()
  },
  computed: {
    couleur(): string {
      return this.cejour == this.aujourhui ? '#000' : '#d00'
    }
  },
  filters: {
    heure(value: string) {
      if (value && value.length > 11) {
        return value.substr(11, 5)
      } else {
        return ''
      }
    }
  }
})
