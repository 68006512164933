export function stringFromDate(dte: Date): string {
  if (dte === null) return ''
  if (dte === undefined) return ''
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? '0' : '') + mo
  const dd = (d < 10 ? '0' : '') + d
  return `${y}-${mmo}-${dd}`
}
export function dateFromString(stdate: string /**yyyy-mm-dd */): Date {
  if (stdate == null) return new Date()
  const [year, month, day] = stdate.split('-')
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12)
  return date
}

const jours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
const mois = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
export function dateFrancaise(dte: Date): string {
  const i = dte.getDay()
  const s = jours[i] + ' ' + dte.getDate() + ' ' + mois[dte.getMonth()]
  return s
}

import { openDB } from 'idb'

export function dbPlanning() {
  const db = openDB('agco-ennery', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('planning_jour')) {
        const planning = db.createObjectStore('planning_jour', { keyPath: 'plj_id' }) // create it
        planning.createIndex('date', 'plj_date')
      }
    }
  })
  return db
}
