








































import Vue from 'vue'
import { stringFromDate } from './plugins/fonctions'
const cejour = new Date()
// const cejour = new Date(2021, 3, 1, 0, 0, 0)
const dateEN = cejour.toDateString()

export default Vue.extend({
  name: 'App',
  data() {
    return {
      refresh: 1,
      jour1: stringFromDate(cejour),
      dateEN: dateEN,
      transporteur: '',
      pass: '',
      paramDlg: false,
      showPass: false
    }
  },
  methods: {
    actualiser() {
      let dte = new Date()
      this.jour1 = stringFromDate(dte)
      this.refresh++
    },
    parametres() {
      this.paramDlg = true
    },
    enregistrer() {
      localStorage.setItem('agco_planningtrp_transporteur', this.transporteur)
      localStorage.setItem('agco_planningtrp_motdepasse', this.pass)
      this.paramDlg = false
      this.actualiser()
      this.$router.replace('/' + this.transporteur + '/' + this.pass)
    }
  },
  watch: {
    $route(to, from) {
      this.pass = this.$route.params.pass
      this.transporteur = this.$route.params.transporteur
      this.actualiser()
    }
  },
  mounted() {
    const trp = localStorage.getItem('agco_planningtrp_transporteur')
    if (trp) this.transporteur = trp
    const pass = localStorage.getItem('agco_planningtrp_motdepasse')
    if (pass) this.pass = pass

    if (this.$route.params.pass) this.pass = this.$route.params.pass
    if (this.$route.params.transporteur) this.transporteur = this.$route.params.transporteur
    this.$router.replace('/' + this.transporteur + '/' + this.pass)
    //}
  }
})
